import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Listing`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-panel-reference/broadcast-contacts/listing-panel/"
        }}>{`Broadcast Contacts`}</a>{` - A broadcast are streaming that you do using  CoreMedia Digital Experience Platform announcing new products or services to your customers.`}<br /><br />{`Brodcasts have a list of contacts associated. Broadcats Contacts panels list information and metrics associated to the the contacts that were added to the broadcast.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-panel-reference/contacts-attempts/listing-panel/"
        }}>{`Contacts Attempts`}</a>{` - Contacts Attempts contain information and metrics retaled to the attempts to make outbound calls to customers.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-panel-reference/contacts/listing-panel/"
        }}>{`Contacts`}</a>{` - This panel lists all the contacts. A contact is the record of an interaction between a customer an the contact center. The contact includes detailed information about the interactions that the customer had with the contact center. Each customer can have more than one contact.`}<br /><br />{`The contact includes information like the date and duration of the interaction, the purpose and the channel of the interaction, if the interaction used an IVR, the phone number or email used in the interaction, if the interaction was initiated in a Click2Call, among others.`}<br /><br />{`Note that it is not mandatory for the contact to have an operator associated. The customer can end the contact, for example, in an IVR or with a chatbot.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-panel-reference/ecommerce/listing-panel/"
        }}>{`ECommerce`}</a>{` - List of shopping cart activity and associated metrics.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-panel-reference/events/listing-panel/"
        }}>{`Events`}</a>{` - Events are interactions that the webpage or the web application detects. Users generate events when they do one of the following:`}<ul><li>{`Clicks on the web page or web application.`}</li><li>{`Page scroll.`}</li><li>{`Form submissions.`}</li></ul>{`Automated workflows generate events when they do one of the following:`}<ul><li>{`Scheduled updates.`}</li><li>{`Data changes.`}</li></ul>{`CoreMedia Experience Platform generates events when it does one of the following:`}<ul><li>{`Page loads.`}</li><li>{`API responses`}</li><li>{`Error generation.`}</li></ul>{`CoreMedia Experience Platfor use events for web tracking, analytics, and dynamic user experiences.`}<br /><br />{`Events related panels contain the list of all events that users, workflows, and CoreMedia Experience platform generate and contain event associated metrics.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-panel-reference/ivr/listing-panel/"
        }}>{`IVR`}</a>{` - IVRs allow customers to navigate through a menu of a series of pre-recorded messages and options. IVRs help customers to get the information they need and to perform actions like paying bills, checking account balances, or making appointments withou the intervention of a human operator.`}<br /><br />{`IVR Nodes panels list information and metrics associated with IVRs that can include the DTMF Success Rate, IVR name, amond others.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-panel-reference/leads/listing-panel/"
        }}>{`Leads`}</a>{` - `}<a parentName="li" {...{
          "href": "/glossary/#lead"
        }}>{`Leads`}</a>{` are visitors that might be interested in your products. Leads have contacts associated.`}<br /><br />{`Leads panels list information and metrics associated with leads that can include the number of the lead, the number of leads that had sales, the number of leads that didn't had sales, the number of contacts associated with the lead, among others.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-panel-reference/outbound-campaigns-actions/listing-panel/"
        }}>{`Outbound Campaigns Actions`}</a>{` - This panel lists information related the outbound campaigns actions.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-panel-reference/outbound-campaigns-email/listing-panel/"
        }}>{`Outbound Campaigns Email`}</a>{` - Outbound Campaigns - Email are offsite campaigns that you do using CoreMedia Digital Experience Platform to send emails to your customers.`}<br /><br />{`Outbound Campaigns - Email panels list information and metrics associated with the emails that were sent.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-panel-reference/outbound-campaigns-push-actions/listing-panel/"
        }}>{`Outbound Campaigns Push Actions`}</a>{` - This panel lists all actions related to Outbound Campaigns - Push that might include all notification status since the moment that CoreMedia Digital Experince Platform pushes the notifications to the moment that your customers deletes it.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-panel-reference/outbound-campaigns-push/listing-panel/"
        }}>{`Outbound Campaigns Push`}</a>{` - Outbound Campaigns - Push are offsite campaigns that you do using CoreMedia Digital Experience Platform to send push notifications to the browser of your customers.`}<br /><br />{`Outbound Campaigns - Push panels list information and metrics associated with the push notifications.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-panel-reference/outbound-campaigns-sms/listing-panel/"
        }}>{`Outbound Campaigns SMS`}</a>{` - Outbound Campaigns - SMS are offsite campaigns that you do using CoreMedia Digital Experience Platform to send SMS to your customers.`}<br /><br />{`Outbound Campaigns - SMS panels list information and metrics associated with the SMS that were sent.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-panel-reference/outbound-campaigns-voice/listing-panel/"
        }}>{`Outbound Campaigns Voice`}</a>{` - Outbound Campaigns - Voice are offsite campaigns that you do using CoreMedia Digital Experience Platform to make calls to your customers.`}<br /><br />{`Outbound Campaigns - Voice panels list information and metrics associated with the calls that were made.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-panel-reference/visitors/listing-panel/"
        }}>{`Visitors`}</a>{` - A visitor is a person who has visited a webpage that includes the Coremedia Tag. CoreMedia Experience Platform attributes a unique identifier to each visitor and collects detailed information from the visitor open sessions and journey through the website.`}<br /><br />{`CoreMedia Experience Platform saves the visitor unique identifier in a cookie for future use.`}<br /><br />{`Visitors panels list information and metrics associsted with visitors.`}</li>
    </ul>
    <h2>{`Aggregation`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-panel-reference/contacts-attempts/aggregation-panel-metrics/"
        }}>{`Contacts Attempts`}</a>{` - Contacts Attempts contain information and metrics retaled to the attempts to make outbound calls to customers.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-panel-reference/canned-messages/aggregation-panel-metrics/"
        }}>{`Canned Messages`}</a>{` - Canned messages are predefined messages that operators can use to respond to customers. Canned messages help operators to respond quickly to customers and to maintain a consistent voice and tone.`}<br /><br />{`Canned messages panels show the number of interactions associated with canned messages.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-panel-reference/chatbot-stats/aggregation-panel-metrics/"
        }}>{`Chatbot Stats`}</a>{` - Chatbot Stats panels contain metrics that allow you to track the performance of the chatbot. The metrics include the number and percentage of node interactions, the number of node session expirations, and the time spent on each node.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-panel-reference/chatbot/aggregation-panel-metrics/"
        }}>{`Chatbot`}</a>{` - A chatbot is a virtual assistan that allow your customers and your operators to interact with CoreMedia Digital Experience Platform. Chatbots help customers to get the information they need.`}<br /><br />{`Chatbot panels contain metrics related to the chatbot that include the number of clicks2call, the number of click2chats, and the number of interactions.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-panel-reference/contact-center-activity/aggregation-panel-metrics/"
        }}>{`Contact Center Activity`}</a>{` - Metrics associated the activity of the contact center.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-panel-reference/contacts-sales/aggregation-panel-metrics/"
        }}>{`Contacts Sales`}</a>{` - This panel lists information that allow you to keep track of sales. The metrics include the number of bundles, the number and price of RGUs, and the number of contacts with sales.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-panel-reference/contacts/aggregation-panel-metrics/"
        }}>{`Contacts`}</a>{` - This panel lists all the contacts. A contact is the record of an interaction between a customer an the contact center. The contact includes detailed information about the interactions that the customer had with the contact center. Each customer can have more than one contact.`}<br /><br />{`The contact includes information like the date and duration of the interaction, the purpose and the channel of the interaction, if the interaction used an IVR, the phone number or email used in the interaction, if the interaction was initiated in a Click2Call, among others.`}<br /><br />{`Note that it is not mandatory for the contact to have an operator associated. The customer can end the contact, for example, in an IVR or with a chatbot.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-panel-reference/content/aggregation-panel-metrics/"
        }}>{`Content`}</a>{` - Content related panels allow you to track performance of impressions in your site. Impressions are the number of times a content is displayed to a user. The metrics include the number of impressions and the number of assoiciated sessions.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-panel-reference/conversions/aggregation-panel-metrics/"
        }}>{`Conversions`}</a>{` - Conversions are the number of times that a user completes a desired action. The desired action can be a purchase, a registration, a download, or any other action that you want the user to complete.`}<br /><br />{`Conversions panels include metrics like the conversion value and the number of conversions that allow you to keep track of profits.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-panel-reference/ecommerce/aggregation-panel-metrics/"
        }}>{`ECommerce`}</a>{` - List of shopping cart activity and associated metrics.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-panel-reference/events/aggregation-panel-metrics/"
        }}>{`Events`}</a>{` - Events are interactions that the webpage or the web application detects. Users generate events when they do one of the following:`}<ul><li>{`Clicks on the web page or web application.`}</li><li>{`Page scroll.`}</li><li>{`Form submissions.`}</li></ul>{`Automated workflows generate events when they do one of the following:`}<ul><li>{`Scheduled updates.`}</li><li>{`Data changes.`}</li></ul>{`CoreMedia Experience Platform generates events when it does one of the following:`}<ul><li>{`Page loads.`}</li><li>{`API responses`}</li><li>{`Error generation.`}</li></ul>{`CoreMedia Experience Platfor use events for web tracking, analytics, and dynamic user experiences.`}<br /><br />{`Events related panels contain the list of all events that users, workflows, and CoreMedia Experience platform generate and contain event associated metrics.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-panel-reference/ivr-nodes/aggregation-panel-metrics/"
        }}>{`IVR Nodes`}</a>{` - An IVR node is a menu option in the complete IVR menu flow.`}<br /><br />{`IVR Nodes panels list information and metrics associated with activity in each node.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-panel-reference/ivr/aggregation-panel-metrics/"
        }}>{`IVR`}</a>{` - IVRs allow customers to navigate through a menu of a series of pre-recorded messages and options. IVRs help customers to get the information they need and to perform actions like paying bills, checking account balances, or making appointments withou the intervention of a human operator.`}<br /><br />{`IVR Nodes panels list information and metrics associated with IVRs that can include the DTMF Success Rate, IVR name, amond others.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-panel-reference/leads/aggregation-panel-metrics/"
        }}>{`Leads`}</a>{` - `}<a parentName="li" {...{
          "href": "/glossary/#lead"
        }}>{`Leads`}</a>{` are visitors that might be interested in your products. Leads have contacts associated.`}<br /><br />{`Leads panels list information and metrics associated with leads that can include the number of the lead, the number of leads that had sales, the number of leads that didn't had sales, the number of contacts associated with the lead, among others.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-panel-reference/onsite-campaigns/aggregation-panel-metrics/"
        }}>{`Onsite Campaigns`}</a>{` - Onsite campaigns are campaigns add pop-ups and forms to your website to increase your customers engagement with your website. For example, showing a pop-up with a discount to a customer that is about to leave your website.`}<br /><br />{`Onsite campaigns panels list information and metrics associated with the onsite campaigns that can include the number of clicks, the number of impressions, the number of conversions, among others.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-panel-reference/outbound-campaigns-actions/aggregation-panel-metrics/"
        }}>{`Outbound Campaigns Actions`}</a>{` - This panel lists information related the outbound campaigns actions.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-panel-reference/outbound-campaigns-email/aggregation-panel-metrics/"
        }}>{`Outbound Campaigns Email`}</a>{` - Outbound Campaigns - Email are offsite campaigns that you do using CoreMedia Digital Experience Platform to send emails to your customers.`}<br /><br />{`Outbound Campaigns - Email panels list information and metrics associated with the emails that were sent.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-panel-reference/outbound-campaigns-push-actions/aggregation-panel-metrics/"
        }}>{`Outbound Campaigns Push Actions`}</a>{` - This panel lists all actions related to Outbound Campaigns - Push that might include all notification status since the moment that CoreMedia Digital Experince Platform pushes the notifications to the moment that your customers deletes it.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-panel-reference/outbound-campaigns-sms/aggregation-panel-metrics/"
        }}>{`Outbound Campaigns SMS`}</a>{` - Outbound Campaigns - SMS are offsite campaigns that you do using CoreMedia Digital Experience Platform to send SMS to your customers.`}<br /><br />{`Outbound Campaigns - SMS panels list information and metrics associated with the SMS that were sent.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-panel-reference/outbound-campaigns-voice/aggregation-panel-metrics/"
        }}>{`Outbound Campaigns Voice`}</a>{` - Outbound Campaigns - Voice are offsite campaigns that you do using CoreMedia Digital Experience Platform to make calls to your customers.`}<br /><br />{`Outbound Campaigns - Voice panels list information and metrics associated with the calls that were made.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-panel-reference/segments-actions/aggregation-panel-metrics/"
        }}>{`Segments Actions`}</a>{` - This panel lists all the actions related with segmentation activty. Actions include adding and removing visitors from segments.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-panel-reference/visitors/aggregation-panel-metrics/"
        }}>{`Visitors`}</a>{` - A visitor is a person who has visited a webpage that includes the Coremedia Tag. CoreMedia Experience Platform attributes a unique identifier to each visitor and collects detailed information from the visitor open sessions and journey through the website.`}<br /><br />{`CoreMedia Experience Platform saves the visitor unique identifier in a cookie for future use.`}<br /><br />{`Visitors panels list information and metrics associsted with visitors.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-panel-reference/visits/aggregation-panel-metrics/"
        }}>{`Visits`}</a>{` - This panel list information related with visitors accesses to your website.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-panel-reference/voice-stats/aggregation-panel-metrics/"
        }}>{`Voice Stats`}</a>{` - This panel lists information containing voice calls statistics.`}</li>
    </ul>
    <h2>{`Metric`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-panel-reference/contacts-attempts/metrics/"
        }}>{`Contacts Attempts`}</a>{` - Contacts Attempts contain information and metrics retaled to the attempts to make outbound calls to customers.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-panel-reference/canned-messages/metrics/"
        }}>{`Canned Messages`}</a>{` - Canned messages are predefined messages that operators can use to respond to customers. Canned messages help operators to respond quickly to customers and to maintain a consistent voice and tone.`}<br /><br />{`Canned messages panels show the number of interactions associated with canned messages.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-panel-reference/chatbot-stats/metrics/"
        }}>{`Chatbot Stats`}</a>{` - Chatbot Stats panels contain metrics that allow you to track the performance of the chatbot. The metrics include the number and percentage of node interactions, the number of node session expirations, and the time spent on each node.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-panel-reference/chatbot/metrics/"
        }}>{`Chatbot`}</a>{` - A chatbot is a virtual assistan that allow your customers and your operators to interact with CoreMedia Digital Experience Platform. Chatbots help customers to get the information they need.`}<br /><br />{`Chatbot panels contain metrics related to the chatbot that include the number of clicks2call, the number of click2chats, and the number of interactions.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-panel-reference/contact-center-activity/metrics/"
        }}>{`Contact Center Activity`}</a>{` - Metrics associated the activity of the contact center.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-panel-reference/contacts-sales/metrics/"
        }}>{`Contacts Sales`}</a>{` - This panel lists information that allow you to keep track of sales. The metrics include the number of bundles, the number and price of RGUs, and the number of contacts with sales.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-panel-reference/contacts/metrics/"
        }}>{`Contacts`}</a>{` - This panel lists all the contacts. A contact is the record of an interaction between a customer an the contact center. The contact includes detailed information about the interactions that the customer had with the contact center. Each customer can have more than one contact.`}<br /><br />{`The contact includes information like the date and duration of the interaction, the purpose and the channel of the interaction, if the interaction used an IVR, the phone number or email used in the interaction, if the interaction was initiated in a Click2Call, among others.`}<br /><br />{`Note that it is not mandatory for the contact to have an operator associated. The customer can end the contact, for example, in an IVR or with a chatbot.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-panel-reference/content/metrics/"
        }}>{`Content`}</a>{` - Content related panels allow you to track performance of impressions in your site. Impressions are the number of times a content is displayed to a user. The metrics include the number of impressions and the number of assoiciated sessions.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-panel-reference/conversions/metrics/"
        }}>{`Conversions`}</a>{` - Conversions are the number of times that a user completes a desired action. The desired action can be a purchase, a registration, a download, or any other action that you want the user to complete.`}<br /><br />{`Conversions panels include metrics like the conversion value and the number of conversions that allow you to keep track of profits.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-panel-reference/ecommerce/metrics/"
        }}>{`ECommerce`}</a>{` - List of shopping cart activity and associated metrics.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-panel-reference/events/metrics/"
        }}>{`Events`}</a>{` - Events are interactions that the webpage or the web application detects. Users generate events when they do one of the following:`}<ul><li>{`Clicks on the web page or web application.`}</li><li>{`Page scroll.`}</li><li>{`Form submissions.`}</li></ul>{`Automated workflows generate events when they do one of the following:`}<ul><li>{`Scheduled updates.`}</li><li>{`Data changes.`}</li></ul>{`CoreMedia Experience Platform generates events when it does one of the following:`}<ul><li>{`Page loads.`}</li><li>{`API responses`}</li><li>{`Error generation.`}</li></ul>{`CoreMedia Experience Platfor use events for web tracking, analytics, and dynamic user experiences.`}<br /><br />{`Events related panels contain the list of all events that users, workflows, and CoreMedia Experience platform generate and contain event associated metrics.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-panel-reference/ivr-nodes/metrics/"
        }}>{`IVR Nodes`}</a>{` - An IVR node is a menu option in the complete IVR menu flow.`}<br /><br />{`IVR Nodes panels list information and metrics associated with activity in each node.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-panel-reference/ivr/metrics/"
        }}>{`IVR`}</a>{` - IVRs allow customers to navigate through a menu of a series of pre-recorded messages and options. IVRs help customers to get the information they need and to perform actions like paying bills, checking account balances, or making appointments withou the intervention of a human operator.`}<br /><br />{`IVR Nodes panels list information and metrics associated with IVRs that can include the DTMF Success Rate, IVR name, amond others.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-panel-reference/leads/metrics/"
        }}>{`Leads`}</a>{` - `}<a parentName="li" {...{
          "href": "/glossary/#lead"
        }}>{`Leads`}</a>{` are visitors that might be interested in your products. Leads have contacts associated.`}<br /><br />{`Leads panels list information and metrics associated with leads that can include the number of the lead, the number of leads that had sales, the number of leads that didn't had sales, the number of contacts associated with the lead, among others.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-panel-reference/onsite-campaigns/metrics/"
        }}>{`Onsite Campaigns`}</a>{` - Onsite campaigns are campaigns add pop-ups and forms to your website to increase your customers engagement with your website. For example, showing a pop-up with a discount to a customer that is about to leave your website.`}<br /><br />{`Onsite campaigns panels list information and metrics associated with the onsite campaigns that can include the number of clicks, the number of impressions, the number of conversions, among others.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-panel-reference/outbound-campaigns-actions/metrics/"
        }}>{`Outbound Campaigns Actions`}</a>{` - This panel lists information related the outbound campaigns actions.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-panel-reference/outbound-campaigns-email/metrics/"
        }}>{`Outbound Campaigns Email`}</a>{` - Outbound Campaigns - Email are offsite campaigns that you do using CoreMedia Digital Experience Platform to send emails to your customers.`}<br /><br />{`Outbound Campaigns - Email panels list information and metrics associated with the emails that were sent.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-panel-reference/outbound-campaigns-push-actions/metrics/"
        }}>{`Outbound Campaigns Push Actions`}</a>{` - This panel lists all actions related to Outbound Campaigns - Push that might include all notification status since the moment that CoreMedia Digital Experince Platform pushes the notifications to the moment that your customers deletes it.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-panel-reference/outbound-campaigns-sms/metrics/"
        }}>{`Outbound Campaigns SMS`}</a>{` - Outbound Campaigns - SMS are offsite campaigns that you do using CoreMedia Digital Experience Platform to send SMS to your customers.`}<br /><br />{`Outbound Campaigns - SMS panels list information and metrics associated with the SMS that were sent.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-panel-reference/outbound-campaigns-voice/metrics/"
        }}>{`Outbound Campaigns Voice`}</a>{` - Outbound Campaigns - Voice are offsite campaigns that you do using CoreMedia Digital Experience Platform to make calls to your customers.`}<br /><br />{`Outbound Campaigns - Voice panels list information and metrics associated with the calls that were made.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-panel-reference/segments-actions/metrics/"
        }}>{`Segments Actions`}</a>{` - This panel lists all the actions related with segmentation activty. Actions include adding and removing visitors from segments.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-panel-reference/visitors/metrics/"
        }}>{`Visitors`}</a>{` - A visitor is a person who has visited a webpage that includes the Coremedia Tag. CoreMedia Experience Platform attributes a unique identifier to each visitor and collects detailed information from the visitor open sessions and journey through the website.`}<br /><br />{`CoreMedia Experience Platform saves the visitor unique identifier in a cookie for future use.`}<br /><br />{`Visitors panels list information and metrics associsted with visitors.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-panel-reference/visits/metrics/"
        }}>{`Visits`}</a>{` - This panel list information related with visitors accesses to your website.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-panel-reference/voice-stats/metrics/"
        }}>{`Voice Stats`}</a>{` - This panel lists information containing voice calls statistics.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      